import React, { SyntheticEvent } from "react";

import SEO from "@shared/seo";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { useNotFoundQuery } from "@api";
import { addSendInBlueContact } from "@util/helper";
import { Button } from "@global";

const NotFoundPage = () => {
  const notFoundData = useNotFoundQuery();

  if (!notFoundData) return null;

  const { pageMeta } = notFoundData;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug="/404" />
      <Hero data={pageMeta.pageHero} overflowIsHidden />

      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
};

export default NotFoundPage;
